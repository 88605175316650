<script setup>

import {DateTime} from "luxon";
import axios from "axios";
import {ref, defineEmits, defineProps} from "vue";
const emit = defineEmits(["change"])
const props = defineProps({
  hasMore: {
    type: Boolean,
    default: false
  },
  toDate: {
    type: Boolean,
    default: false
  },
  uuid: {
    type: String,
    default: ''
  },
  items: {
    type: [Array, Object, null],
    default: null
  },
  context: {
    type: Object,
    default: () => ({})
  }
})
const isOpen = ref(false);
const selected = ref('Weitere Termine');
const items = ref({...props.items});
const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectItem = ( index) => {
  selected.value = props.toDate ?   DateTime.fromISO(index).toLocaleString(DateTime.DATE_FULL) : index;
  // Das Dropdown bleibt geöffnet, da wir `isOpen.value` nicht ändern
  emit("change", index)
  isOpen.value = false
};


const loadMore = async () => {
  try {
    // Ersetzen Sie die URL mit Ihrer tatsächlichen Endpoint-URL
    const response = await axios.post('https://api.meinplaner.com/listing/' + props.uuid, {
      offset: Object.values(items.value).length,
      context: props.context
    });
    // Annahme: response.data enthält ein Array von Optionen { text: '...', value: '...' }
    const newItems = {};

    // Fügen Sie die neuen Optionen zur bestehenden Liste hinzu
    for (const [key, value] of Object.entries(response.data)) {
      items.value[key] = value;
      newItems[key] = value; // Füge neue Daten zu newItems hinzu

    }
    emit("push", newItems)

  } catch (error) {
    console.error('Fehler beim Laden weiterer Optionen:', error);
  }
};
const handleOutsideClick = () => {
  isOpen.value = false
}
</script>

<template>

  <div v-if="items" class="dropdown" @click="toggleDropdown" v-outside-click="() =>handleOutsideClick()">
    <div class="dropdown-selected">{{ selected }}</div>
    <div class="dropdown-content" v-if="isOpen">
      <div
          class="dropdown-item"
          v-for="(item, index) in items"
          :key="item"
          @click.stop="selectItem(index)"
      >
        {{ props.toDate ?   DateTime.fromISO(index).toLocaleString(DateTime.DATE_FULL) : item  }}
      </div>
      <div
          class="dropdown-item"
          v-if="props.hasMore"
          @click.stop="loadMore"
      >
        Mehr Termine laden
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.dropdown {

  min-width: 120px;
  position: relative;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 200px;
  bottom: 100%;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 25px;
}

.dropdown-item {
  padding: 6px 12px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-selected {
  position: relative;
  width: 100%;
  display: flex;
  min-width: 120px;
  max-width: 120px;
  border: 1px solid #d8d8d8;
  order: 2;
  border-radius: 25px;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #d8d8d8;
  align-items: center;
  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: #666;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
  }
}


</style>
