<script setup>


import { DateTime } from 'luxon';
import {computed, ref, watchEffect, defineProps, reactive} from "vue";
import InputDropdown from "@/components/form/input-dropdown.vue";
const props = defineProps({
  item: {
    type: Object
  },
  expand: {
    type: Boolean,
    default: false
  },
  isHighlight: {
    type: Boolean,
    default: false
  },
  noDetail: {
    type: Boolean,
    default: false
  },
  isSearch: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: 'default'
  },
  current: {
    type: String,
    default: null
  },
  checkProfile: {

    type: Boolean,
    default: false
  },
  slider: {

    type: Boolean,
    default: false
  }
})
const state = reactive({
  items: { ...props.item.opening?.items }
});
const appointment = ref(props.item)
const showMore = ref(false)
const appointmentPhotographInfo = ref(false)
const appointmentMoreInfo = ref(false)
const currentAppointment = ref(null)
const statusTypes = ref([
  {
    id: 2,
    title: 'Ausverkauft',
    class: 'status__red'
  },
  {
    id: 1,
    title: 'Nur mit Anmeldung',
    class: 'status__green__2'
  },
  {
    id: 3,
    title: 'Verschoben',
    class: 'status__red'
  },
  {
    id: 4,
    title: 'Abgesagt',
    class: 'status__red'
  },
  {
    id: 5,
    title: 'Aktion',
    class: 'status__orange'
  },
  {
    id: 6,
    title: 'Line-up',
    class: 'status__black'
  }
])
// #95C11F
const getStatus = (id) => {
  return statusTypes.value.find(s => s.id === id)
}
const firstDate = computed(() => Object.keys(props.item.opening.items)[0]);
const selectedDate = ref('');
const selectedTimes = ref({
  date: firstDate.value,
  times: props.item.opening.items[firstDate.value]
});

const hasCategoryConfig = (tags) => {
  if(tags && tags.edges && tags.edges.find(c => c.node.isMain && c.node.config)){
    return tags.edges.find(c => c.node.isMain && c.node.config).node.config
  }
  return  null
}


const showAppointment = async (uuid) => {
  currentAppointment.value = uuid





}


const photographData = (listing) => {
  const getPicturesData = (pictures) => {
    if (pictures && pictures.edges && pictures.edges.length > 0) {
      const result = pictures.edges[0].node.picture;

      if (result && (result.photograph || result.copyright)) {
        return result.copyright.trim().length !== 0
            ? result.copyright
            : `${result.photograph.firstname} ${result.photograph.lastname}`;
      }
    }

    return null;
  };

  const eventData = [
    listing.pictures,
    listing.referTo ? listing.referTo.pictures : null,
    listing.location.pictures
  ];

  for (const pictures of eventData) {
    const data = getPicturesData(pictures);
    if (data !== null) {
      return data;
    }
  }

  return null;


}
const setCurrentPhotographData = () => {
  appointmentPhotographInfo.value = !appointmentPhotographInfo.value;
}

const setAppointmentClass = (uuid) => {
  return 'appointment__' + uuid
}

watchEffect(() => {
  if (props.current !== appointment.value.uuid) {

    appointmentMoreInfo.value = false
    currentAppointment.value = null
  }else{

    currentAppointment.value = props.current
  }
})




const otherDates = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { [firstDate.value]: firstDateEntry, ...rest } = state.items
  return rest;
});

const updateSelectedDay = (event) => {
  selectedDate.value = event
  updateSelectedTimes()
}

const addDayItems = (items) => {

  for (const [key, value] of Object.entries(items)) {
    if (!(key in state.items)) {

      // Füge die neuen Daten direkt zu state.items hinzu
      state.items[key] = value;
    }
  }
}
function updateSelectedTimes() {

  if(selectedDate.value === ''){
    selectedTimes.value = {
      date: firstDate.value,
      times:  state.items[firstDate.value]
    }
  }else{
    selectedTimes.value = {
      date: selectedDate.value,
      times:  state.items[selectedDate.value] || []
    };
  }


}


const isDropdownOpen = ref(false);
const sharedLink = computed(() => {
  return 'https://www.meinplaner.com/event/' + appointment.value?.uuid;
});
const whatsappLink = computed(() => {
  return `https://api.whatsapp.com/send?text=Schau%20dir%20dieses%20Event%20auf%20meinplaner%20an:%20${encodeURIComponent(sharedLink.value)}`;
});


const emailLink = computed(() => {
  return `mailto:?subject=Schau%20dir%20dieses%20Event%20an&body=Hier%20ist%20der%20Link:%20${encodeURIComponent(sharedLink.value)}`;
});
const facebookLink = computed(() => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedLink.value)}`;
});
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};
const getItemClasses = (appointment) => {
  return {
    'has__focus': isFocused(appointment),
    'has__opacity': currentAppointment.value !== props.current && props.current !== null,
  };
};
const getClassObject = (appointment) => {
  return {
    'has__focus__outer': currentAppointment.value === appointment.uuid,
    'is_embedded': props.mode === 'embedded',
    [setAppointmentClass(appointment.uuid)]: true,
    'has__no__profile': props.checkProfile && (!appointment.location.profile || (appointment.location.profile && appointment.location.profile.isPublished === false))
  };
};

const isFocused = (appointment) => {
  return currentAppointment.value === appointment.uuid || props.expand === 1;
};
const hasPhotograph = (appointment) => {
  const hasPicturesData = (pictures) => pictures?.edges?.length && (pictures.edges[0].node.picture.photograph || pictures.edges[0].node.picture.copyright);

  return [appointment.location.pictures, appointment.pictures, appointment.referTo?.pictures].some(hasPicturesData);
};
const shouldRenderPictures = (appointment) => {
  return !props.checkProfile || (props.checkProfile && (appointment.location.profile && appointment.location.profile.isPublished));
};

const getPictureUrl = (appointment, type) => {
  const pictures = type === 'content' ? 'contentUrl' : 'thumbnailWebpUrl';
  let pictureData;

  if (appointment.pictures.totalCount) {
    pictureData = appointment.pictures.edges[0].node.picture.file;
  } else if (appointment.referTo && appointment.referTo.pictures.totalCount) {
    pictureData = appointment.referTo.pictures.edges[0].node.picture.file;
  } else if (appointment.location.pictures.totalCount) {
    pictureData = appointment.location.pictures.edges[0].node.picture.file;
  }

  return pictureData ? pictureData[pictures] : null;
}
const appointmentDescription = computed(() => {
  if( selectedTimes.value && selectedTimes.value.times && selectedTimes.value.times[0] && selectedTimes.value.times[0].meta){
    return  selectedTimes.value.times[0].meta.description !== "" && selectedTimes.value.times[0].meta.description !== "<p></p>" ? selectedTimes.value.times[0].meta.description: null
  }

  return null
})
const appointmentInfo = (type) => {
  if( selectedTimes.value && selectedTimes.value.times && selectedTimes.value.times[0] && selectedTimes.value.times[0].meta){
    return  selectedTimes.value.times[0].meta[type]
  }

  return null
}

const listingId = () => {
  if( selectedTimes.value && selectedTimes.value.times && selectedTimes.value.times[0] && selectedTimes.value.times[0].meta){
    return  selectedTimes.value.times[0].listing
  }

  return null
}



const openMoreInfo = (uuid, event) => {
  showAppointment(uuid, event)
  appointmentMoreInfo.value = true
}







function formatDate(date) {
  const inputDate = DateTime.fromISO(date);
  const now = DateTime.now();

  if (inputDate.hasSame(now, 'day')) {
    return 'heute';
  } else {
    return inputDate.toFormat('dd.MM.yy');
  }
}

function isToday(date){
  const parsedDate = DateTime.fromISO(date);
  return parsedDate.hasSame(DateTime.now(), 'day');
}



const convertTimeToDate = (timeString, referenceDate) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return DateTime.fromISO(referenceDate).set({ hour: hours, minute: minutes, second: 0 });
};

const isOpenNow = (from, to, referenceDate) => {
  const now = DateTime.now();
  const fromTime = convertTimeToDate(from, referenceDate);
  let toTime = convertTimeToDate(to, referenceDate);

  if (toTime < fromTime) {
    toTime = toTime.plus({ days: 1 });
  }

  return now >= fromTime && now <= toTime;
};

const isNotExpired = (to, referenceDate) => {
  const now = DateTime.now();
  let toTime = convertTimeToDate(to, referenceDate);

  if (toTime < DateTime.fromISO(referenceDate).endOf('day')) {
    toTime = toTime.plus({ days: 1 });
  }

  return now >= toTime;
};

</script>

<template>
  <div class="event__item" :class="getClassObject(appointment)" :id="`appointment__${appointment.uuid}`">
    <div class="event__item__content__meta">
      <div class="meta__user__icons">
        <a :href="`https://api.meinplaner.com/share/calendar/${listingId()}`" target="_blank">
          <svg class="icon"><use xlink:href="#icon-event-calendar-add"></use></svg>
        </a>
        <div class="button__share__interaction" v-outside-click="() => { isDropdownOpen = false }">
          <button type="button" @click="toggleDropdown"><svg class="icon"><use xlink:href="#icon-event-share"></use></svg></button>
          <div v-if="isDropdownOpen" class="share__dropdown">
            <div class="share__headline">Teilen:</div>
            <div class="share__links">
              <a :href="whatsappLink" target="_blank">
                <svg class="icon"><use xlink:href="#icon-share-whatsapp"></use></svg> WhatsApp
              </a>
              <a :href="emailLink" target="_blank">
                <svg class="icon"><use xlink:href="#icon-share-email"></use></svg> E-Mail
              </a>
              <a :href="facebookLink" target="_blank">
                <svg class="icon"><use xlink:href="#icon-share-facebook"></use></svg> Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="event__item__inner" :class="getItemClasses(appointment)">
      <div class="event__left">
        <figure class="is__event" v-if="shouldRenderPictures(appointment)">
          <span class="highlight__badge" v-if="props.isHighlight">
            <!-- SVG Badge -->
          </span>
          <template v-if="appointment.isLinkedProfile && appointment.location.isPublished">
            <a :href="`https://www.meinplaner.com/location/${appointment.location.slug}`" target="_blank">
              <picture>
                <source media="(max-width: 799px)" :srcset="getPictureUrl(appointment, 'thumbnail')" />
                <source media="(min-width: 800px)" :srcset="getPictureUrl(appointment, 'thumbnailXmUrl')" />
                <img :src="getPictureUrl(appointment, 'thumbnailXmUrl')" :alt="appointment.location.title" />
              </picture>
            </a>
          </template>
          <template v-else>
            <picture>
              <source media="(max-width: 799px)" :srcset="getPictureUrl(appointment, 'thumbnail')" />
              <source media="(min-width: 800px)" :srcset="getPictureUrl(appointment, 'thumbnailXmUrl')" />
              <img :src="getPictureUrl(appointment, 'thumbnailXmUrl')" :alt="appointment.location.title" />
            </picture>
          </template>
          <template v-if="hasPhotograph(appointment)">
            <div class="photograph__icon" @click="setCurrentPhotographData(appointment.uuid, appointment.uuid)">
              <svg class="icon">
                <use xlink:href="#icon-photograph"></use>
              </svg>
            </div>
            <div class="photograph__overlay" :class="{is__show: appointmentPhotographInfo}">
              <button type="button" class="close__photograph" @click.stop="setCurrentPhotographData(appointment.uuid)">
                <svg class="icon">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
              <div class="photograph__overlay__head">
                <svg class="icon">
                  <use xlink:href="#icon-photograph"></use>
                </svg> Copyright
              </div>
              <div class="photograph__overlay__content">
                {{ photographData(appointment) }}
              </div>
            </div>
          </template>
          <div class="event__tags">
            <span v-if="appointmentInfo('freeTickets') > 0" class="status__green">Freikarten</span>
            <span v-if="getStatus(appointmentInfo('status'))" :class="getStatus(appointmentInfo('status')).class" @click="openMoreInfo(appointment.uuid, appointment.uuid)">{{ getStatus(appointmentInfo('status')).title }}</span>
          </div>
        </figure>
      </div>
      <div class="event__right">
        <div class="event__item__content">

          <div class="event__headline__group">

            <div class="event__item__dates">
              <div class="event__item__dates__date">
                <span class="day">{{ formatDate(selectedTimes.date) }}</span>

                  <template v-for="(time, index) in selectedTimes.times" :key="index">
                    <template v-if="time.from && time.isOpen">
                      <span class="time" v-if="time.isAllDay">ganztägig</span>
                      <span v-else-if="time.to && time.closingSoon && isToday(selectedTimes.date)" class="time highlight">schließt {{ time.to }} Uhr</span>
                      <template v-else-if="time.to && !isNotExpired(time.to, selectedTimes.date)">
                        <span v-if="isOpenNow(time.from, time.to, selectedTimes.date) && isToday(selectedTimes.date)" class="time">geöffnet bis {{ time.to }} Uhr</span>
                        <span class="time" v-else>{{time.from}} - {{time.to}} Uhr</span>
                      </template>
                      <span class="time" v-else>{{ time.from }} Uhr</span>
                    </template>
                    <span v-else class="time highlight">geschlossen</span>
                  </template>

              </div>

            </div>
            <div class="event__headline__text">
              <div class="event__item__headline" lang="de" :title="appointment.title">{{appointment.title}}</div>
              <div class="event__item__location">
                <a :href="`https://www.meinplaner.com/location/${appointment.location.slug}`" target="_blank"><strong>{{appointment.location.title}}</strong></a>,
                {{appointment.location.address.city.code}} {{appointment.location.address.city.city}}
              </div>
            </div>
          </div>

          <div class="event__item__description">
            {{appointment.clientDescription ? appointment.clientDescription : (appointment.description ? appointment.description : (appointment.referTo ? appointment.referTo.description : '')) }}</div>

          <div class="event__item__admission">{{appointmentInfo('admission')}}</div>


          <div v-if="appointment.attachments.edges.length" class="event__item__files">
            <div class="event__item__files__headline">Anhänge: </div>
            <a :href="attachment.node.file.contentUrl" target="_blank" class="event__item__files__item" v-for="attachment in appointment.attachments.edges" :key="attachment.node.id">{{attachment.node.title}}</a>
          </div>
          <template v-if="showMore">
            <div class="event__item__more" :class="{ [getStatus(appointmentInfo('status'))?.class]: true}" v-html="appointmentInfo('descriptionFormatted')"></div>

          </template>

          <div class="event__item__data">
            <div class="event__item__data__icons">
              <button v-if="appointmentDescription" type="button" @click="showMore= !showMore">
                <svg class="icon"><use xlink:href="#icon-info"></use></svg>
                <span class="icon__label">{{ showMore ? 'Weniger Details' : 'Mehr Details'}}</span>
              </button>
              <div v-if="Object.keys(otherDates).length" class="event__date__select">
                <input-dropdown
                    @change="updateSelectedDay"
                    @push="addDayItems"
                    :has-more="appointment.opening.count > Object.values(state.items).length"
                    :items="state.items"
                    :uuid="appointment.uuid"
                    :context="props.item.opening.context"
                    :to-date="true"
                ></input-dropdown>
              </div>
              <a v-if="appointmentInfo('ticketLink') && !hasCategoryConfig(appointment.tags)?.icon" :href="appointmentInfo('ticketLink')" target="_blank">
                <svg class="icon"><use xlink:href="#icon-event-ticket"></use></svg>
                <span class="icon__label">{{ hasCategoryConfig(appointment.tags) && hasCategoryConfig(appointment.tags).label ? hasCategoryConfig(appointment.tags).label : 'Tickets' }}</span>
              </a>
              <a v-else-if="hasCategoryConfig(appointment.tags)?.icon && appointmentInfo('ticketLink')" :href="`${appointmentInfo('ticketLink')}`" target="_blank">
                <svg class="icon"><use :xlink:href="`#${hasCategoryConfig(appointment.tags).icon}`"></use></svg>
                <span class="icon__label">{{ hasCategoryConfig(appointment.tags).label }}</span>
              </a>
              <a v-else-if="appointmentInfo('ticketPhone')" :href="`tel:${appointmentInfo('ticketPhone')}`" target="_blank">
                <svg class="icon"><use xlink:href="#icon-event-phone"></use></svg>
                <span class="icon__label">{{ hasCategoryConfig(appointment.tags) && hasCategoryConfig(appointment.tags).label ? hasCategoryConfig(appointment.tags).label : 'Tickets' }}</span>
              </a>
              <a v-else-if="hasCategoryConfig(appointment.tags)?.icon && appointment.location.reservationLink" :href="`${appointment.location.reservationLink}`" target="_blank">
                <svg class="icon"><use :xlink:href="`#${hasCategoryConfig(appointment.tags).icon}`"></use></svg>
                <span class="icon__label">{{ hasCategoryConfig(appointment.tags).label }}</span>
              </a>
              <a v-if="appointment.location.address" class="pin__icon" :href="`https://www.google.com/maps?q=${appointment.location.latitude},${appointment.location.longitude}`" target="_blank">
                <svg class="icon"><use xlink:href="#icon-event-map"></use></svg>
                <span class="icon__label">Maps</span>
              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.event__item {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  border-bottom: 1px dotted #dadada;
  padding-bottom: 30px;
  margin-bottom: 30px;


  &__files{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    &__headline{
      font-weight: 700;
    }
    &__item{
      color: #af255b;

    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__content__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
  }

  .meta__user__icons {
    display: flex;
    align-items: center;

    a,
    button {
      margin-left: 10px;
      cursor: pointer;
      background: none;
      border: none;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .button__share__interaction {
      position: relative;

      .share__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1000;

        .share__headline {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .share__links {
          display: flex;
          flex-direction: column;

          a {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            color: black;
            text-decoration: none;

            svg {
              margin-right: 5px;
            }

            &:hover {
              background-color: #f0f0f0;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__content {
    position: relative;
  }
}

.event__headline__group {
  margin-bottom: 20px;
  display: inline-flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .event__item__dates {
    font-size: 13px;

    &__date {
      .day {
        font-weight: 700;
        background: #af255b;
        color: #fff;
        margin-right: 8px;
        border-radius: 25px;
        padding: 2px 10px;
      }

      .time {
        background: #dadada;
        color: #111;
        margin-right: 8px;
        border-radius: 25px;
        padding: 2px 10px;
      }
    }
  }
}

.event__item__inner {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}



.event__left {
  width: 100%;

  @media (min-width: 768px) {
    width: 30%;
  }

  figure {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;

    img {
      width: 100%;
      height: auto;
      min-height: 185px;
      object-fit: cover;
      border-radius: 10px;
      display: block;
    }

    .highlight__badge {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: yellow;
      padding: 5px 10px;
      border-radius: 3px;
    }

    .photograph__icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .photograph__overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      &.is__show {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .close__photograph {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
          fill: #fff
        }
      }

      .photograph__overlay__head {
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 700;

        svg {
          height: 18px;
          margin-right: 10px;
          width: 18px;
        }
      }

      .photograph__overlay__content {
        font-size: 16px;
        text-align: center;
      }
    }

    .event__tags {
      position: absolute;
      bottom: 10px;
      left: 10px;
      display: flex;

      span {
        background: #dadada;
        border-radius: 25px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        margin-right: 8px;
        padding: 4px 10px;

        &.status__green {
          background: #0f0;
          color: #111;
        }

        &.status__orange {
          background: #ff9212;
          border: 1px solid #fff;
          color: #fff;
        }

        &.status__green__2 {
          background: #6e9600;
          border: 1px solid #fff;
          color: #fff;
        }

        &.status__black {
          background: #111;
          border: 1px solid #fff;
          color: #fff;
        }

        &.status__red {
          background: #ff1212;
          color: #fff;
        }
      }
    }
  }
}

.event__item__admission{
  margin-bottom: 1rem;
}
.event__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 70%;
    padding: 0 20px 10px 20px;
  }

  .event__item__headline {
    font-size: 22px;
    font-weight: bold;
    margin: 10px 0 0 0;
  }

  .event__item__description {
    margin-bottom: 1rem;
  }

  .event__item__more {
    margin-bottom: 1rem;
    font-size: 14px;
    opacity: 0.6;

    p {
      margin-top: 0;

      a {
        color: #af255b;
      }
    }

    &.status__green {
      color: #0f0;
    }

    &.status__red {
      color: #f00;
    }

    &.status__orange {
      color: #ff9212;
    }

    &.status__black {
      color: #000;
    }
  }

  .event__item__location {
    margin-top: 2px;

    a {
      color: #af255b;
    }
  }

  .event__item__data {
    display: flex;
    flex-direction: column;

    .event__item__data__icons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 1rem;
      align-items: center;

      button svg{
        fill: #af255b;
      }
      a, button {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        text-decoration: none;
        border: none;
        color: inherit;
        border-radius: 25px;
        background: #dadada;
        padding: 4px 12px;

        svg {
          width: 18px;
          height: 18px;
        }

        .icon__label {
          font-size: 14px;
        }
      }
    }

    .event__item__dates,
    .event__item__admission {
      margin-top: 1rem;
    }

    .event__item__dates {
      display: flex;
      flex-wrap: wrap;

      .event__item__dates__date {
        margin-right: 1rem;
        margin-bottom: 1rem;

        .day {
          font-weight: bold;
          margin-right: 5px;
        }

        .times {
          display: flex;
          flex-wrap: wrap;

          .time {
            margin-right: 5px;

            &.highlight {
              color: red;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}


</style>

